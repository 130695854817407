import { useEffect, useState } from "react";
import { deleteHomePageHotArticle, fetchHomePageHotArticle } from "../../api/homepage";
import { Card, Image, Pagination, Flex, Button, Avatar, Space, Badge, Popconfirm, message, Spin, Descriptions, Tag, Input, Select, DatePicker } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { htmlImgSrc } from "../../utils/comm";
import HotArticleEditView from "../../components/homepage/hot_article_edit_view";
import PostReview from "../../components/community/post_review";
import { useDebouncedCallback } from "use-debounce";
import dayjs from "dayjs";
import { Typography } from "antd";

const HomeArticleScreen = () => {
    const [articles, setArticles] = useState([]);
    const [open, setOpen] = useState(false);
    const [currArticle, setCurrArticle] = useState(null);
    const [reviewOpen, setReviewOpen] = useState(false);
    const [searchParams, setSearchParams] = useState({
        title: '',
        status: '',
        start: '',
        end: '',
    });
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getData(pageNum, pageSize, searchParams);
    }, [])

    const getData = (pNum, pSize, search) => {
        setLoading(true);
        let params = {
            'page.num': pNum,
            'page.size': pSize,
            ...search
        }
        fetchHomePageHotArticle(params).then(res => {
            if (res.list) {
                setArticles(res.list);
            }
            if (res.page) {
                setTotal(res.page.total);
            }
            setLoading(false);
        })
    }

    const getAddedPanel = () => {
        return (
            <Card
                loading={loading}
                hoverable
                style={{ width: 320 }}
                cover={<Image preview={false} style={{ width: 320, height: 180, objectFit: 'cover' }} fallback="/images/placeholder.svg" />}>
                <Card.Meta title={
                    <Flex justify="center">
                        <Button type="dashed" shape="round" size="large" icon={<PlusOutlined />} onClick={() => editHandle(null)}>
                            Add Article
                        </Button>
                    </Flex>
                }
                    description={<div style={{ color: 'white' }}>AD</div>} />
            </Card>
        )
    }

    const editHandle = (record) => {
        setCurrArticle(record);
        setOpen(true);
    }

    const deleteHandle = (record) => {
        record.isLoading = true;
        setArticles([...articles]);
        deleteHomePageHotArticle(record.id).then(res => {
            getData(pageNum, pageSize, searchParams);
        }).catch(err => {
            message.error(err.message);
            record.isLoading = false;
            setArticles([...articles]);
        })
    }

    const reviewHandle = (record) => {
        setCurrArticle(record);
        setReviewOpen(true);
    }

    const getArticlePanels = () => {
        return (
            <>
                {
                    articles.map((item, index) => {
                        let avatar = '/images/avatar_default.svg';
                        const { user } = item.post;
                        if (user.avatar || user.picture) {
                            avatar = user.picture ?? user.avatar;
                        } else if (user.email.includes('addxgo.io')) {
                            avatar = '/images/logo.png';
                        }
                        let imgs = htmlImgSrc(item.post.content).map(src => src.replaceAll("&amp;", "&"));
                        let nickName = 'Anonymous';
                        if (user.name || user.username) {
                            nickName = user.name ? user.name : user.username;
                        }
                        let color = '#f5222d';
                        if (index === 1) {
                            color = '#fa8c16';
                        } else if (index === 2) {
                            color = '#d4b106';
                        }
                        return (
                            <div key={item.id} style={{ position: 'relative' }}>
                                <Badge.Ribbon text={item.weight} color={color}>
                                    <Card
                                        loading={loading}
                                        hoverable
                                        style={{ width: 320 }}
                                        cover={
                                            <Image
                                                preview={false}
                                                style={{ width: 320, height: 180, objectFit: 'cover' }}
                                                fallback="/images/placeholder.svg"
                                                src={imgs.length > 0 ? imgs[0] : null} />
                                        } >
                                        <Card.Meta
                                            avatar={<Avatar src={avatar} size={44} />}
                                            title={item.post.title}
                                            description={
                                                <Flex justify="space-between" align="center">
                                                    <Typography.Text style={{ maxWidth: 100, color: '#999', fontSize: 12 }} ellipsis>{nickName}</Typography.Text>
                                                    {
                                                        item.isLoading === true ?
                                                            <Spin indicator={<LoadingOutlined spin />} /> :
                                                            <Space size={0}>
                                                                <Button
                                                                    shape="circle"
                                                                    type="text"
                                                                    icon={<EyeOutlined style={{ color: '#afafaf' }} />}
                                                                    onClick={() => reviewHandle(item)} />
                                                                <Button
                                                                    shape="circle"
                                                                    type="text"
                                                                    icon={<EditOutlined style={{ color: '#afafaf' }} />}
                                                                    onClick={() => editHandle(item)} />
                                                                <Popconfirm
                                                                    title="Delete Action"
                                                                    description="Are you sure to delete this hot article?"
                                                                    onConfirm={() => { deleteHandle(item) }}
                                                                    okText="Yes"
                                                                    cancelText="No" >
                                                                    <Button shape="circle" type="text" icon={<DeleteOutlined style={{ color: '#afafaf' }} />} />
                                                                </Popconfirm>
                                                            </Space>
                                                    }
                                                </Flex>
                                            } />
                                    </Card>
                                </Badge.Ribbon>
                                <Tag color={String(item.status).toLowerCase() === 'active' ? 'green' : 'red'} style={{ position: 'absolute', left: 10, top: 10 }}>{item.status}</Tag>
                            </div>
                        )
                    })
                }
            </>
        )
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        getData(page, size, searchParams);
    }

    const onSearchChange = useDebouncedCallback(
        (tag, value) => {
            let newParams = { ...searchParams };
            if (tag === 'dates') {
                if (value.length == 2) {
                    newParams.start = `${value[0]}T00:00:00Z`;
                    newParams.end = `${value[1]}T23:59:59Z`;
                } else {
                    newParams.start = '';
                    newParams.end = '';
                }
            } else {
                newParams[tag] = value;
            }
            setSearchParams(newParams);
            setPageNum(1);
            getData(1, pageSize, newParams);
        },
        300
    )

    const getExtraView = () => {
        return (
            <Space>
                <Input
                    placeholder="keywords search"
                    allowClear
                    onChange={evt => onSearchChange('title', evt.target.value)}
                />
                <Select
                    style={{ width: 160 }}
                    options={[
                        { key: 1, label: 'Active', value: 0 },
                        { key: 2, label: 'Inactive', value: 1 },
                    ]}
                    placeholder='status'
                    onChange={value => onSearchChange('status', value)}
                    allowClear
                />
                <DatePicker.RangePicker onChange={values => onSearchChange('dates', (values ?? []).map(date => dayjs(date).format('YYYY-MM-DD')))} />
            </Space>
        )
    }

    return (
        <div style={{ padding: 20 }}>
            <Descriptions title='Hot Articles' extra={getExtraView()}>
                <Descriptions.Item>
                    <Space size={16} wrap>
                        {getAddedPanel()}
                        {getArticlePanels()}
                    </Space>
                </Descriptions.Item>
            </Descriptions>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>
            <HotArticleEditView
                open={open}
                defaultArticle={currArticle}
                onClose={() => setOpen(false)}
                onUpdate={() => getData(pageNum, pageSize, searchParams)} />
            <PostReview
                from="ha"
                open={reviewOpen}
                post={currArticle ? currArticle.post : null}
                onClose={() => setReviewOpen(false)}
                canEdit={false} />
        </div>
    );
}

export default HomeArticleScreen;