import { useEffect, useState } from 'react'
import { Button, Popconfirm, Table, Pagination, Space, message, Avatar, Typography, Flex } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { createAdminWallet, deleteAdminWallet, fetchWallets, updateAdminWallet } from '../../api/crypto';
import { capitalizeFirstLetter } from '../../utils/comm';

const WalletScreen = () => {
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'index',
            key: 'index',
            render: (text, _, index) => {
                return (pageNum - 1) * pageSize + index + 1;
            }
        },
        {
            title: 'Wallet ID',
            dataIndex: 'id',
            key: 'id',
            render: (text, _) => {
                return <Typography.Text copyable={{ text: text }}><b>{text}</b></Typography.Text>;
            }
        },
        {
            title: 'Wallet Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                let val = text;
                return (
                    <Typography.Text editable={
                        {
                            onChange: (value) => {
                                val = value;
                            },
                            onEnd: () => {
                                editHandle({ id: record.id, name: val });
                            }
                        }
                    }>{text}</Typography.Text>
                );
            }
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            render: (_, record) => {
                return (
                    <Space direction='vertical' >
                        {
                            record.ethAddress && <Typography.Text copyable>{record.ethAddress}</Typography.Text>
                        }
                        {
                            record.btcAddress && <Typography.Text copyable>{record.btcAddress}</Typography.Text>
                        }
                    </Space>
                )
            }
        },
        {
            title: 'Balances',
            dataIndex: 'balances',
            key: 'balances',
            render: (text, _) => {
                let tokens = Array.from(text ?? []).filter(item => Number(item.balance) > 0);
                return (
                    <ul>
                        {
                            tokens.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <Flex align='center' size={1}>
                                            <Flex style={{ fontSize: 12, fontWeight: 500, whiteSpace: 'nowrap' }} align='center'>
                                                <span style={{ color: '#0266FF', fontWeight: 600, marginRight: 4 }}>{item.balance}</span>
                                                {item.symbol}
                                            </Flex>
                                            {
                                                item.chain.includes('ethereum') ?
                                                    <Avatar src="/images/eth.svg" size={16} />
                                                    :
                                                    (
                                                        item.chain.includes('polygon') ?
                                                            <Avatar src="/images/matic.svg" size={16} />
                                                            :
                                                            (
                                                                item.chain.includes('bitcoin') ?
                                                                    <Avatar src="/images/btc.svg" size={16} />
                                                                    :
                                                                    null
                                                            )
                                                    )
                                            }
                                        </Flex>
                                    </li>
                                )
                            })
                        }
                    </ul>
                );
            }
        },
        {
            title: 'CreatedAt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                return <label style={{ fontSize: 12, color: '#afafaf' }}>{dayjs(text).format('YYYY-MM-DD HH:mm')}</label>
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        <Popconfirm
                            title="Delete Action"
                            description="Are you sure to delete this wallet?"
                            onConfirm={() => { deleteHandle(record) }}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link'>Delete</Button>
                        </Popconfirm>
                    </Button.Group>
                )
            }
        }
    ];

    useEffect(() => {
        setLoading(true);
        getWallets(pageNum, pageSize);
    }, [])

    const getWallets = (pNum, pSize) => {
        let params = {
            'page.num': pNum,
            'page.size': pSize,
        }
        fetchWallets(params).then(res => {
            if (res.list) {
                setTabData(res.list);
            }
            if (res.page) {
                setTotal(res.page.total);
            }
        }).finally(() => setLoading(false))
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        getWallets(page, size);
    }

    const createWallet = () => {
        setLoading(true);
        createAdminWallet({}).then(res => {
            if (res) {
                getWallets(pageNum, pageSize);
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    const deleteHandle = (record) => {
        setLoading(true);
        deleteAdminWallet(record.id).then(res => {
            if (res) {
                message.success('deleted!');
                getWallets(pageNum, pageSize);
            }
        }).catch(err => {
            message.error(String(err));
        }).finally(() => {
            setLoading(false);
        })
    }

    const editHandle = (params) => {
        setLoading(true);
        updateAdminWallet(params).then(res => {
            if (res) {
                message.success('updated!');
                getWallets(pageNum, pageSize);
            }
        }).catch(err => {
            message.error(String(err));
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Button type='primary' icon={<PlusOutlined />} onClick={createWallet}>Create Wallet</Button>
            </div>
            <div className='cnt-item'>
                <Table loading={loading} rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>
        </div>
    )
}

export default WalletScreen;