import React, { useEffect, useState } from "react";
import '../../style/login.css';
import { Navigate } from 'react-router-dom';
import { getQueryString } from '../../utils/comm';
import { setCookie } from '../../utils/cookie';
import { firebaseAuthApp } from "../../firebase/auth";
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import BackgroundImage from '../../assets/gobg.jpg';
import { Input, Button, ConfigProvider, message } from 'antd';

const { Password } = Input;

const LoginPanel = (props) => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState();
    const [pwd, setPwd] = useState();
    const [needRedirect, setNeedRedirect] = useState(false);
    const [loading, setLoading] = useState(false);
    const redirectPath = getQueryString('redirect');

    useEffect(() => {
    }, [])

    useEffect(() => {
        if (username) {
            setNeedRedirect(true);
        }
    }, [username]);

    const emailInput = (evt) => {
        setEmail(evt.target.value)
    }

    const pwdInput = (evt) => {
        setPwd(evt.target.value);
    }

    const confirmLogin = async () => {
        setLoading(true);
        let isSuccess = true;
        try {
            let credential = await signInWithEmailAndPassword(getAuth(firebaseAuthApp()), email, pwd);
            if (credential && credential.user) {
                setCookie('user-token', credential.user.accessToken);
                window.localStorage.setItem('rjwt', credential.user.stsTokenManager.refreshToken);
                
                setUsername(credential.user.email);
            } else {
                throw new Error("sign in failed");
            }
        } catch (error) {
            // console.log(error);
            isSuccess = false;
            message.error(error.message ? error.message : 'sign in failed, please try again');
        }

        setLoading(false);
    }

    return (
        <ConfigProvider theme={{
            components: {
                Button: {
                    colorBgContainerDisabled: '#D0DFFC',
                    colorBgContainer: '#0266FF',
                    colorTextDisabled: 'white',
                    borderColorDisabled: '#D0DFFC'
                }
            }
        }}>
            <div className="main" style={{ backgroundImage: `url(${BackgroundImage})` }}>
                <div className="login-form">
                    <h1>Addx Go Admin</h1>
                    <div className="head">
                        <img src='/images/logo.png' alt="" />
                    </div>

                    <div style={{ margin: '0 32px' }}>
                        <div className="email mt-10">
                            <div className="f-dark f-r-14">Email address</div>
                            <div className="mt-1 relative">
                                <Input className="input f-r-16" placeholder="Enter your email address" onChange={emailInput} allowClear />
                            </div>
                        </div>

                        <div className="email mt-4">
                            <div className="f-dark f-r-14">Password</div>
                            <div className="mt-1">
                                <Password className="input f-r-16" placeholder="Input password" onChange={pwdInput} allowClear />
                            </div>
                        </div>

                        <Button loading={loading} onClick={confirmLogin} disabled={!email || !pwd} type="primary" className="mt-10 f-m-16 next-btn mb-1" block>Log in</Button>

                    </div>
                    <div style={{ height: 32 }} />
                </div>
                {
                    needRedirect ? <Navigate to={`/${redirectPath ? redirectPath : ''}`} props={{ account: { name: username } }} replace={true} /> : null
                }
            </div>
        </ConfigProvider>
    );
}

export default LoginPanel;